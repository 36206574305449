import Vue from 'vue';
import App from './App.vue';
import ElementUI from 'element-ui';
import { Container, Button, Menu, Icon, Switch, Message, Loading, Select, Notification, MessageBox, Card, Slider } from 'element-ui';

import 'leaflet/dist/leaflet.css'
// 引入Leaflet对象 挂载到Vue上，便于全局使用，也可以单独页面中单独引用
Vue.use(ElementUI);
Vue.use(Container, Button, Menu, Icon, Switch, Message, Loading, Select, Notification, MessageBox, Card, Slider)
Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')
