<template>
  <div>
    <el-switch
  class="asideSwitch1"
  v-model="value1"
  active-text="显示模型"
  inactive-text="关闭模型"
  @change="toggleEntitys">
    </el-switch>  
    <el-switch
  class="asideSwitch2"
  v-model="value2"
  active-text="显示地球"
  inactive-text="关闭地球"
  @change="toggleScene">
    </el-switch>
    
    <el-card class="box-card">
    <div slot="header" class="clearfix">
      <span style="font-size: 20px;">操作指南</span>
    </div>
    <div class="text item">
      {{'鼠标中键：控制视角'}}
    </div>
    <div class="text item">
      {{'左键双击：经纬度查询'}}
    </div>
    <div class="text item" style="line-height: 26px;">
      {{'右键模型：环绕效果 (再次右键取消环绕)'}}
    </div>
    <div class="text item">
      {{'左键单击模型：显示基本信息'}}
    </div>
    <div class="text item">
      {{'建模：刘某某'}}
    </div>
    <div class="text item">
      {{'优化：李某某'}}
    </div>
    <div class="text item">
      {{'集成：李某某'}}
    </div>
    <div class="text item">
      {{'开发：吴某'}}
    </div>
    </el-card>
  </div>
</template>

<script>
  import eventAsideMain from '../utils/eventAsideMain';
  export default {
    data() {
      return {
        value1: true,
        value2: true,
      }
    },
    mounted(){
      eventAsideMain.$on('toggle-ModelShow',this.toggleModelShow)
    },
    methods:{
      toggleEntitys(value){
        eventAsideMain.$emit('toggle-entitys',value)
      },
      toggleScene(value){
        eventAsideMain.$emit('toggle-scene',value)
      },
      toggleModelShow(value){
          this.value1 = value
      }
    }
  };
</script>

<style scoped>
 .asideSwitch1{
  margin-top: 20px;
  margin-left: 20px;
 }
 .asideSwitch2{
  margin-top: 30px;
  margin-left: 20px;
 }

 .text {
    font-size: 16px;
  }
  .item {
    margin-bottom: 18px;
  }
  .clearfix:before,
  .clearfix:after {
    display: table;
    content: "";
  }
  .clearfix:after {
    clear: both
  }
  .box-card {
    width: 265px;
    margin-top: 100px;
  }
</style>