<template>
  <div class="parents">
    <el-button size="mini" @click="toggleCesium" class="layer-btn cesiumbtn">Cesium</el-button>
    <el-button size="mini" @click="toggleLeaflet" class="layer-btn leafletbtn">Leaflet</el-button>
    <el-button size="mini" @click="runToycar" class="layer-btn runToycar">汽车模型</el-button>
    <el-button size="mini" @click="pauseToycar" class="layer-btn pauseToycar">汽车暂停</el-button>
    <el-button size="mini" @click="resumeToycar" class="layer-btn resumeToycar">汽车继续</el-button>
    <div id="leafletmap">
    </div>
    <div ref="canvas" :class="{ 'hand-cursor': isHovering }" v-loading="loading" id="cesiumContainer" style="width: 100%; height: 100%;">
    <div class="weather-tools" v-show="weatherTool">
      <el-select
        v-model="weatherVal"
        placeholder="请选择天气"
        @change="weatherChange">
        <el-option
          v-for="item in weatherOpts"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
      <el-button size="mini" @click="weatherClose" class="close-btn">关闭</el-button>
    </div>
  </div>
  </div>
</template>

<script type="module">
import L from 'leaflet';
import imageBus from '@/utils/imageEvent'; // 用于与其他组件通信
import eventAsideMain from '@/utils/eventAsideMain'; // 另一个事件总线，用于场景和实体切换
import WeatherRain from "../../public/Cesium/CesiumWeather/WeatherRain"; // 导入雨效果
import WeatherSnow from "../../public/Cesium/CesiumWeather/WeatherSnow"; // 导入雪效果
import WeatherFog from "../../public/Cesium/CesiumWeather/WeatherFog";   // 导入雾效果

// 全局存储天气对象，避免重复实例化
let weatherObjs = {
  rain: undefined,
  snow: undefined,
  fog: undefined,
};

export default {
  data(){
    return {
      Lon: 0,// 经度查询
      Lat: 0,// 纬度查询
      Height: 0,// 高程
      gymRotateFlag : false, // 体育馆旋转
      loading: false,  // 用于地形切换时的加载状态
      weatherTool: true, // 控制天气工具的显示与隐藏
      weatherVal: "请选择天气", // 当前选择的天气值
      weatherOpts: [
        { value: "rain", label: "雨" },
        { value: "snow", label: "雪" },
        { value: "fog", label: "雾" },
      ], // 天气选项数组
      otherModelArr:[
        // 其他模型的批量添加，例如花坛，树
        {
          position: Cesium.Cartesian3.fromDegrees(112.94304823034636, 28.187674748559978, 48.06915470486108),
          headingPitchRoll: new Cesium.HeadingPitchRoll(
            Cesium.Math.toRadians(90), // 航向角
            Cesium.Math.toRadians(0), // 俯仰角
            Cesium.Math.toRadians(0)  // 翻滚角
          ),
          url: "3Dmodels/flower.glb",
          scale: 0.03
        },
        {
          position: Cesium.Cartesian3.fromDegrees(112.94310651437776, 28.18767922373734, 47.95574378895443),
          headingPitchRoll: new Cesium.HeadingPitchRoll(
            Cesium.Math.toRadians(90),
            Cesium.Math.toRadians(0),
            Cesium.Math.toRadians(0)
          ),
          url: "3Dmodels/flower.glb",
          scale: 0.03
        },
        {
          position: Cesium.Cartesian3.fromDegrees(112.94316450988134, 28.187681086682083, 47.83565387132093),
          headingPitchRoll: new Cesium.HeadingPitchRoll(
            Cesium.Math.toRadians(90), // 航向角
            Cesium.Math.toRadians(0), // 俯仰角
            Cesium.Math.toRadians(0)  // 翻滚角
          ),
          url: "3Dmodels/flower.glb",
          scale: 0.03
        },
        {
          position: Cesium.Cartesian3.fromDegrees(112.94322364706719, 28.187684242258964, 47.713821919961596),
          headingPitchRoll: new Cesium.HeadingPitchRoll(
            Cesium.Math.toRadians(90), // 航向角
            Cesium.Math.toRadians(0), // 俯仰角
            Cesium.Math.toRadians(0)  // 翻滚角
          ),
          url: "3Dmodels/flower.glb",
          scale: 0.03
        },
        {
          position: Cesium.Cartesian3.fromDegrees(112.94328360431442, 28.187688039369714, 47.58368900816718),
          headingPitchRoll: new Cesium.HeadingPitchRoll(
            Cesium.Math.toRadians(90), // 航向角
            Cesium.Math.toRadians(0), // 俯仰角
            Cesium.Math.toRadians(0)  // 翻滚角
          ),
          url: "3Dmodels/flower.glb",
          scale: 0.03
        },
        {
          position: Cesium.Cartesian3.fromDegrees(112.94334215622408, 28.187690816932882, 47.45240481621293),
          headingPitchRoll: new Cesium.HeadingPitchRoll(
            Cesium.Math.toRadians(90), // 航向角
            Cesium.Math.toRadians(0), // 俯仰角
            Cesium.Math.toRadians(0)  // 翻滚角
          ),
          url: "3Dmodels/flower.glb",
          scale: 0.03
        },
        {
          position: Cesium.Cartesian3.fromDegrees(112.94339872809898, 28.187692148558234, 47.32906574215316),
          headingPitchRoll: new Cesium.HeadingPitchRoll(
            Cesium.Math.toRadians(90), // 航向角
            Cesium.Math.toRadians(0), // 俯仰角
            Cesium.Math.toRadians(0)  // 翻滚角
          ),
          url: "3Dmodels/flower.glb",
          scale: 0.03
        },
        {
          position: Cesium.Cartesian3.fromDegrees(112.94345929379392, 28.187696996649663, 47.21279227017446),
          headingPitchRoll: new Cesium.HeadingPitchRoll(
            Cesium.Math.toRadians(90), // 航向角
            Cesium.Math.toRadians(0), // 俯仰角
            Cesium.Math.toRadians(0)  // 翻滚角
          ),
          url: "3Dmodels/flower.glb",
          scale: 0.03
        },
        {
          position: Cesium.Cartesian3.fromDegrees(112.94351586739714, 28.187700965661204, 47.10453280597666),
          headingPitchRoll: new Cesium.HeadingPitchRoll(
            Cesium.Math.toRadians(90), // 航向角
            Cesium.Math.toRadians(0), // 俯仰角
            Cesium.Math.toRadians(0)  // 翻滚角
          ),
          url: "3Dmodels/flower.glb",
          scale: 0.03
        },
        {
          position: Cesium.Cartesian3.fromDegrees(112.9435785807428, 28.187704969910566, 47.156780176787805),
          headingPitchRoll: new Cesium.HeadingPitchRoll(
            Cesium.Math.toRadians(90), // 航向角
            Cesium.Math.toRadians(0), // 俯仰角
            Cesium.Math.toRadians(0)  // 翻滚角
          ),
          url: "3Dmodels/flower.glb",
          scale: 0.03
        },
        {
          position: Cesium.Cartesian3.fromDegrees(112.94363573536398, 28.187706946122812, 47.75040382520303),
          headingPitchRoll: new Cesium.HeadingPitchRoll(
            Cesium.Math.toRadians(90), // 航向角
            Cesium.Math.toRadians(0), // 俯仰角
            Cesium.Math.toRadians(0)  // 翻滚角
          ),
          url: "3Dmodels/flower.glb",
          scale: 0.03
        },
        {
          position: Cesium.Cartesian3.fromDegrees(112.94308874073852, 28.187660228530184, 47.968538542116455),
          headingPitchRoll: new Cesium.HeadingPitchRoll(
            Cesium.Math.toRadians(0), // 航向角
            Cesium.Math.toRadians(0), // 俯仰角
            Cesium.Math.toRadians(0)  // 翻滚角
          ),
          url: "3Dmodels/tree.glb",
          scale: 0.06
        },
        {
          position: Cesium.Cartesian3.fromDegrees(112.94320786669532, 28.18766918904216, 47.72900579650177),
          headingPitchRoll: new Cesium.HeadingPitchRoll(
            Cesium.Math.toRadians(0), // 航向角
            Cesium.Math.toRadians(0), // 俯仰角
            Cesium.Math.toRadians(0)  // 翻滚角
          ),
          url: "3Dmodels/tree.glb",
          scale: 0.06
        },
        {
          position: Cesium.Cartesian3.fromDegrees(112.94331840552852, 28.187677120116646, 47.48587327681296),
          headingPitchRoll: new Cesium.HeadingPitchRoll(
            Cesium.Math.toRadians(0), // 航向角
            Cesium.Math.toRadians(0), // 俯仰角
            Cesium.Math.toRadians(0)  // 翻滚角
          ),
          url: "3Dmodels/tree.glb",
          scale: 0.06
        },
        {
          position: Cesium.Cartesian3.fromDegrees(112.94337882231108, 28.18768124109878, 47.35770794406825),
          headingPitchRoll: new Cesium.HeadingPitchRoll(
            Cesium.Math.toRadians(0), // 航向角
            Cesium.Math.toRadians(0), // 俯仰角
            Cesium.Math.toRadians(0)  // 翻滚角
          ),
          url: "3Dmodels/tree.glb",
          scale: 0.06
        },
        {
          position: Cesium.Cartesian3.fromDegrees(112.94347738011538, 28.1876860870604, 47.1620062257831),
          headingPitchRoll: new Cesium.HeadingPitchRoll(
            Cesium.Math.toRadians(0), // 航向角
            Cesium.Math.toRadians(0), // 俯仰角
            Cesium.Math.toRadians(0)  // 翻滚角
          ),
          url: "3Dmodels/tree.glb",
          scale: 0.06
        },
        {
          position: Cesium.Cartesian3.fromDegrees(112.9435599266236, 28.18769316965247, 47.09338152432832),
          headingPitchRoll: new Cesium.HeadingPitchRoll(
            Cesium.Math.toRadians(0), // 航向角
            Cesium.Math.toRadians(0), // 俯仰角
            Cesium.Math.toRadians(0)  // 翻滚角
          ),
          url: "3Dmodels/tree.glb",
          scale: 0.06
        },
        {
          position: Cesium.Cartesian3.fromDegrees(112.9431365080945, 28.18766696118954, 47.87453480005678),
          headingPitchRoll: new Cesium.HeadingPitchRoll(
            Cesium.Math.toRadians(0), // 航向角
            Cesium.Math.toRadians(0), // 俯仰角
            Cesium.Math.toRadians(0)  // 翻滚角
          ),
          url: "3Dmodels/light1.glb",
          scale: 0.02
        },
        {
          position: Cesium.Cartesian3.fromDegrees(112.94326247187995, 28.187674235551082, 47.60984306850515),
          headingPitchRoll: new Cesium.HeadingPitchRoll(
            Cesium.Math.toRadians(0), // 航向角
            Cesium.Math.toRadians(0), // 俯仰角
            Cesium.Math.toRadians(0)  // 翻滚角
          ),
          url: "3Dmodels/light1.glb",
          scale: 0.02
        },
        {
          position: Cesium.Cartesian3.fromDegrees(112.94334872785112, 28.187679164189486, 47.41917527623612),
          headingPitchRoll: new Cesium.HeadingPitchRoll(
            Cesium.Math.toRadians(0), // 航向角
            Cesium.Math.toRadians(0), // 俯仰角
            Cesium.Math.toRadians(0)  // 翻滚角
          ),
          url: "3Dmodels/light1.glb",
          scale: 0.02
        },
        {
          position: Cesium.Cartesian3.fromDegrees(112.94342685103194, 28.187684159595563, 47.2633806394624),
          headingPitchRoll: new Cesium.HeadingPitchRoll(
            Cesium.Math.toRadians(0), // 航向角
            Cesium.Math.toRadians(0), // 俯仰角
            Cesium.Math.toRadians(0)  // 翻滚角
          ),
          url: "3Dmodels/light1.glb",
          scale: 0.02
        },
        {
          position: Cesium.Cartesian3.fromDegrees(112.94351716965595, 28.187691148468932, 47.08828623620123),
          headingPitchRoll: new Cesium.HeadingPitchRoll(
            Cesium.Math.toRadians(0), // 航向角
            Cesium.Math.toRadians(0), // 俯仰角
            Cesium.Math.toRadians(0)  // 翻滚角
          ),
          url: "3Dmodels/light1.glb",
          scale: 0.02
        },
        {
          position: Cesium.Cartesian3.fromDegrees(112.94361280284802, 28.187697966450735, 47.60605129601275),
          headingPitchRoll: new Cesium.HeadingPitchRoll(
            Cesium.Math.toRadians(0), // 航向角
            Cesium.Math.toRadians(0), // 俯仰角
            Cesium.Math.toRadians(0)  // 翻滚角
          ),
          url: "3Dmodels/light1.glb",
          scale: 0.02
        },
      ],
      modelEntity:[],
      isHovering: false, // 控制光标状态
    }
  },
  async mounted() {
    // leaflet初始化地图
    this.map = L.map('leafletmap').setView([28.186093062027645, 112.94804062738567], 17);
   // 添加高德地图的瓦片图层
    L.tileLayer('http://webrd02.is.autonavi.com/appmaptile?lang=zh_cn&size=1&scale=1&style=7&x={x}&y={y}&z={z}', {
    maxZoom: 18,
    attribution: '高德地图'
    }).addTo(this.map);

    Cesium.Ion.defaultAccessToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiIwMGQ2OTY0OS05OWNiLTRmZDctYTcwNi1lYTY1NTVhODBhNDYiLCJpZCI6MjM5MTExLCJpYXQiOjE3MjU0NTU0ODZ9.JbglzGJ8dBc1tbZbb9GTKEuRlxyE6mXZKIWJ7E_diW4'; // 替换为实际 Cesium 令牌
    this.viewer = new Cesium.Viewer('cesiumContainer', {
      animation: false,
      timeline: false,
      fullscreenButton: true,
      homeButton: false,
      sceneModePicker: false,
      baseLayerPicker: false,
      geocoder: false,
      navigationHelpButton: false,
      infoBox: false,
      selectionIndicator: false,
      terrainProvider: new Cesium.EllipsoidTerrainProvider(), // 默认使用椭球地形
    });
    this.viewer._cesiumWidget._creditContainer.style.display = "none"; // 隐藏 Cesium 的版权信息
    this.imageLayers = this.viewer.imageryLayers; // 图层管理
    this.scene = this.viewer.scene;
    this.scene.globe.depthTestAgainstTerrain = true; // 启用深度测试
    this.clock = this.viewer.clock;
    this.viewer.scene.globe.enableLighting = true; // 启用光照
    this.viewer.scene.light = new Cesium.DirectionalLight({
    direction: Cesium.Cartesian3.fromDegrees(120, 180, 80),
    intensity: 2 // 增加环境光强度
    });

    // 叠加天地图地形服务
    var token = '2501982e131a281d49765ab584c34c24';
    // 服务域名
    var tdtUrl = 'https://t{s}.tianditu.gov.cn/';
    var subdomains = ['0', '1', '2', '3', '4', '5', '6', '7'];
    var terrainUrls = new Array();
    for (var i = 0; i < subdomains.length; i++){
        var url = tdtUrl.replace('{s}', subdomains[i]) + 'mapservice/swdx?T=elv_c&tk=' + token;
        terrainUrls.push(url);
    }
    this.TDTprovider = new Cesium.GeoTerrainProvider({
        urls: terrainUrls
    });
    // 捕获并忽略地形加载错误
    this.TDTprovider.errorEvent.addEventListener((tileProviderError) => {
    //console.warn("忽略地形加载错误: ", tileProviderError.message);
    // 可以选择在这里记录错误信息，但不再进一步处理
    tileProviderError.retry = false;  // 禁止重试加载此瓦片
    });
    this.viewer.terrainProvider = this.TDTprovider;

    //添加吉林一号底图
    this.JILINprovider = await Cesium.IonImageryProvider.fromAssetId(2825696);
    this.scene.imageryLayers.addImageryProvider(this.JILINprovider);
    //添加无人机底图
    this.wurenjiprovider = await Cesium.IonImageryProvider.fromAssetId(2840590);
    this.scene.imageryLayers.addImageryProvider(this.wurenjiprovider);

    // 将模型旋转（Heading: 偏航, Pitch: 俯仰, Roll: 滚转）
    const heading = Cesium.Math.toRadians(-93.7); // 偏航角
    const pitch = 0.0;   // 保持水平
    const roll = 0.0;    // 无滚转
    // 模型的位置
    const positionGym = Cesium.Cartesian3.fromDegrees(112.94393024623155, 28.187859411675053, 46.53260167483407);
    //const position = Cesium.Cartesian3.fromDegrees(112.94354062738567, 28.188093062027645, 50);  0.8缩放

    // 创建实体并设置模型和旋转角度
    // 设置加载状态为 true
    try {
      this.loading = true;
      const entityGym = await this.viewer.entities.add({
      name: '江湾体育馆',
      position: positionGym,
      model: {
        uri: '3Dmodels/test14.glb', // 替换为实际模型路径
        scale: 1,
        minimumPixelSize: 128,
        maximumScale: 20000,
        //maximumScreenSpaceError: 0.5, // 调整 LOD 切换阈值
      },
      properties:{
        Buildingname:"江湾体育馆",
        time:"建成时间：2024.5"
      },
      // 设置模型的方向
      orientation: Cesium.Transforms.headingPitchRollQuaternion(positionGym, new Cesium.HeadingPitchRoll(heading, pitch, roll))
      });
      //entityGym.maximumScreenSpaceError = 0.5; // 更低的值会提高远距离的模型质量
      const entityGymGlass = this.viewer.entities.add({
        name: '玻璃',
        position: positionGym,
        model: {
          uri: '3Dmodels/glass.gltf', // 替换为实际模型路径
          scale: 1,
          minimumPixelSize: 5,
          maximumScale: 20000
        },
        properties:{
          Buildingname:"副场馆入口",
          time:"建成时间：2024.5"
        },
        // 设置模型的方向
        orientation: Cesium.Transforms.headingPitchRollQuaternion(positionGym, new Cesium.HeadingPitchRoll(heading, pitch, roll))
      });
      const entityGymStair = this.viewer.entities.add({
        name: '楼梯',
        position: positionGym,
        model: {
          uri: '3Dmodels/stairs.gltf', // 替换为实际模型路径
          scale: 1,
          minimumPixelSize: 50,
          maximumScale: 20000
        },
        properties:{
          Buildingname:"楼梯",
          time:"建成时间：2024.5"
        },
        // 设置模型的方向
        orientation: Cesium.Transforms.headingPitchRollQuaternion(positionGym, new Cesium.HeadingPitchRoll(heading, pitch, roll))
      });

      // 循环添加树，花坛模型
      // 固定参考系转换
      const fixedFrameTransform = Cesium.Transforms.localFrameToFixedFrameGenerator(
        "north",
        "west"
      );
      for (const modelData of this.otherModelArr) {
      const { position, headingPitchRoll, url, scale } = modelData;
      // 异步加载并添加模型
      Cesium.Model.fromGltfAsync({
        url: url,
        modelMatrix: Cesium.Transforms.headingPitchRollToFixedFrame(
          position,
          headingPitchRoll,
          Cesium.Ellipsoid.WGS84,
          fixedFrameTransform
        ),
        scale: scale,
        minimumPixelSize: 5
      }).then((model) => {
        this.viewer.scene.primitives.add(model);
      }).catch((error) => {
        console.error("模型加载失败:", error);
      });
      }
      this.modelEntity.push(entityGym);
      this.modelEntity.push(entityGymGlass);
      this.modelEntity.push(entityGymStair);
      // 强制延迟 3 秒后才结束加载状态
      setTimeout(() => {
        this.loading = false;
      }, 3000); // 3秒延迟
    } catch (error) {
      console.error("模型加载失败:", error);
      // 如果加载失败，设置加载状态为 false
      this.loading = false;
    }

    this.viewer.flyTo(this.modelEntity, { duration: 3.5 });
    //增加 maximumScreenSpaceError 参数：当视图距离模型较远时，Cesium 会简化模型以提高性能
    this.viewer.scene.globe.maximumScreenSpaceError = 7; // 默认值是2，越小越精细

    // 获取坐标
    var handler = new Cesium.ScreenSpaceEventHandler(this.viewer.scene.canvas);
    handler.setInputAction((movement) => {
    // 获取鼠标点击处的射线
    var ray = this.viewer.camera.getPickRay(movement.position);
    // 射线与地球的交点
    var position = this.viewer.scene.globe.pick(ray, this.viewer.scene);

    if (position) {
      // 将 Cartesian3 坐标转换为经纬度和高度
      var cartographic = Cesium.Ellipsoid.WGS84.cartesianToCartographic(position);

      // 转换为经纬度（以度为单位）
      this.Lon = Cesium.Math.toDegrees(cartographic.longitude);
      this.Lat = Cesium.Math.toDegrees(cartographic.latitude);
      this.Height = cartographic.height;
      this.showLonLat()
    } else {
      console.log("未点击到地球表面");
    }
      },Cesium.ScreenSpaceEventType.LEFT_DOUBLE_CLICK);

    //获取 Cesium 中 左键点击 (LEFT_CLICK) 事件的默认处理程序
    const clickHandler = this.viewer.screenSpaceEventHandler.getInputAction(
       Cesium.ScreenSpaceEventType.LEFT_CLICK
     );
    this.viewer.screenSpaceEventHandler.setInputAction((click) => {
    if (Cesium.defined(click.position)) {
        var pickedObject = this.viewer.scene.pick(click.position);
        if (Cesium.defined(pickedObject)) {
          var gymmodel = this.modelEntity[0];
          var gymglassmodel = this.modelEntity[1];
          if (pickedObject.id === gymmodel) {
                    const imageUrl = '3Dmodels/gym.jpg'; // 替换为图片的路径
                    const htmlMessage = `
                        <div>
                            <img src="${imageUrl}" alt="Image" style="max-width: 100%; height: auto;">
                            <p>${gymmodel.properties.time}</p>
                        </div>
                    `;

                    this.$notify({
                        title: gymmodel.properties.Buildingname,
                        dangerouslyUseHTMLString: true,
                        message: htmlMessage,
                        offset: 150,
                        duration:0
                    });
          }
          if (pickedObject.id === gymglassmodel) {
                    //const imageUrl = '3Dmodels/gym.jpg'; // 替换为图片的路径
                    const htmlMessage = `
                        <div>
                            <p>副场馆入口</p>
                        </div>
                    `;

                    this.$notify({
                        title: gymglassmodel.properties.Buildingname,
                        dangerouslyUseHTMLString: true,
                        message: htmlMessage,
                        offset: 150,
                        duration:0
                    });
          }
        }
        } else {
            clickHandler(click);
        }
    }
   ,Cesium.ScreenSpaceEventType.LEFT_CLICK);

   //获取 Cesium 中 右键点击 (RIGHT_CLICK) 事件的默认处理程序
   const rightHandler = this.viewer.screenSpaceEventHandler.getInputAction(
       Cesium.ScreenSpaceEventType.LEFT_CLICK
     );
     this.viewer.screenSpaceEventHandler.setInputAction((click) => {
      if (Cesium.defined(click.position)) {
          const pickedObject = this.viewer.scene.pick(click.position);
          if (Cesium.defined(pickedObject)) {
              const gymmodel = this.modelEntity[0]; // 假设这是你的目标模型
              if (pickedObject.id === gymmodel) {
                  this.flag = !this.flag;
                  if(this.flag){
                    const targetLongitude = 112.9435573056; // 目标的经度
                    const targetLatitude = 28.1881741944;    // 目标的纬度
                    const targetHeight = 50;          // 目标的高度，可以根据实际情况调整
                    const speed = Cesium.Math.toRadians(1); // 每帧旋转的角度 (1度每帧)
                    let heading = 0; // 初始偏航角
                    const camera = this.viewer.camera;
                    // 将目标经纬度转换为 Cartographic 坐标系
                    const targetCartographic = Cesium.Cartographic.fromDegrees(targetLongitude, targetLatitude, targetHeight);
                    const targetPosition = this.viewer.scene.globe.ellipsoid.cartographicToCartesian(targetCartographic);
                    // 初始化相机视角
                    camera.lookAt(
                        targetPosition, 
                        new Cesium.HeadingPitchRange(heading, Cesium.Math.toRadians(-30), 150)  // 偏航角、俯仰角、距离
                    );
                    // 旋转一圈的逻辑
                    this.rotateHandler = () => {
                        heading += speed;  // 增加偏航角

                        // 更新相机位置
                        camera.lookAt(
                            targetPosition, 
                            new Cesium.HeadingPitchRange(heading, Cesium.Math.toRadians(-30), 150)  // 更新相机视角
                        );

                        // 如果旋转超过360度，就停止
                        if (heading >= Cesium.Math.TWO_PI) {
                            this.viewer.scene.postRender.removeEventListener(this.rotateHandler); // 移除监听器，停止旋转
                        }
                    };
                    // 开始旋转
                    this.viewer.scene.postRender.addEventListener(this.rotateHandler);
                  }else{
                    this.viewer.scene.postRender.removeEventListener(this.rotateHandler);
                    this.viewer.camera.lookAtTransform(Cesium.Matrix4.IDENTITY); // 解除相机绑定，恢复自由操作
                  }
              }
          }
      } else {
          rightHandler(click); // 调用右键点击的处理逻辑
      }
    },Cesium.ScreenSpaceEventType.RIGHT_CLICK);

    // 鼠标经过模型高亮
    let highlightedModel = null; // 用于跟踪当前高亮的模型
    this.viewer.screenSpaceEventHandler.setInputAction((move) => {
    if (Cesium.defined(move.endPosition)) {
        const pickedObject = this.viewer.scene.pick(move.endPosition);
        // 检查是否拾取到对象以及对象是否有 model 属性
        if (Cesium.defined(pickedObject) && pickedObject.id && pickedObject.id.model) {
            // 如果拾取到模型
            if (highlightedModel !== pickedObject.id) {
                // 恢复上一个高亮模型的颜色（如果存在）
                if (highlightedModel) {
                    highlightedModel.model.color = new Cesium.Color(1.0, 1.0, 1.0, 1.0); // 恢复为原色
                }
                // 高亮当前模型
                pickedObject.id.model.color = new Cesium.Color(1.0, 1.0, 0.0, 1.0); // 变为黄色
                highlightedModel = pickedObject.id; // 更新高亮模型
                // 更改光标样式
                this.isHovering = true; // 设置光标状态为手形
            }
        } else {
            // 如果没有对象被拾取或没有 model 属性，恢复上一个高亮模型的颜色
            if (highlightedModel) {
                highlightedModel.model.color = new Cesium.Color(1.0, 1.0, 1.0, 1.0); // 恢复为原色
                highlightedModel = null; // 清除高亮模型
            }
            // 恢复光标样式
            this.isHovering = false; // 设置光标状态为手形
        }
    }
}, Cesium.ScreenSpaceEventType.MOUSE_MOVE);

    // 事件监听器：用于与其他组件通信，处理地图/天气/地形的切换
    imageBus.$on('changeBaseMap', this.changeMap);
    imageBus.$on('toggleJILIN', this.toggleJILIN);
    imageBus.$on('toggleWurenji', this.toggleWurenji);
    imageBus.$on('toggle-terrain', this.toggleTerrain);
    imageBus.$on('toggle-weather', this.toggleWeather);
    eventAsideMain.$on('toggle-entitys', this.toggleEntitys);
    eventAsideMain.$on('toggle-scene', this.toggleScene);
    this.weatherChange(''); // 初次加载时重置天气
  },
  beforeDestroy() {
    // 销毁组件时取消事件监听器，防止内存泄漏
    imageBus.$off('changeBaseMap', this.changeMap);
    imageBus.$off('toggle-terrain', this.toggleTerrain);
    imageBus.$off('toggle-weather', this.toggleWeather);
    eventAsideMain.$off('toggle-entitys', this.toggleEntitys);
    eventAsideMain.$off('toggle-scene', this.toggleScene);
    this.map.remove();
  },
  methods: {
    // 经纬度弹窗
    showLonLat() {
        const h = this.$createElement;
        this.$msgbox({
          title: '经纬度查询',
          message: h('div', { style: {lineHeight: '1.6', color: '#333' } }, [
              h('p', { style: { marginBottom: '8px' } }, [
                  h('span', { style: { fontWeight: 'bold', color: '#007BFF' } }, '经度：'),
                  h('i', { style: { color: '#28a745' } }, this.Lon)
              ]),
              h('p', { style: { marginBottom: '8px' } }, [
                  h('span', { style: { fontWeight: 'bold', color: '#007BFF' } }, '纬度：'),
                  h('i', { style: { color: '#28a745' } }, this.Lat)
              ]),
              h('p', null, [
                  h('span', { style: { fontWeight: 'bold', color: '#007BFF' } }, '高程：'),
                  h('i', { style: { color: '#28a745' } }, this.Height)
              ])
          ]),
          //showCancelButton: true,
          confirmButtonText: '确定',
          //cancelButtonText: '取消',
        })
      },
    // 切换地形：根据选择的值切换 2D 或 3D 地形
    async toggleTerrain(value) {
      this.loading = true;
      var terrainProvider = {}
      eventAsideMain.$emit('toggle-ModelShow',value)
      if(value){
        terrainProvider = this.TDTprovider;
        this.modelEntity.forEach(element => {
        element.show = true
        }); 
      } else {
        terrainProvider = new Cesium.EllipsoidTerrainProvider();
        this.modelEntity.forEach(element => {
        element.show = false
        }); 
      }
      this.viewer.terrainProvider = terrainProvider;
      this.$message({ message: `已切换到 ${value ? '3D' : '2D'} 图层`, type: 'success' });
      this.loading = false;
    },
    // 切换底图：更换地图图层
    changeMap(image) {
      this.imageLayers.removeAll();
      this.imageLayers.addImageryProvider(image);
    },
    // 切换为吉林一号
    async toggleJILIN(){
      this.scene.imageryLayers.addImageryProvider(this.JILINprovider);
    },
    // 添加无人机底图
    toggleWurenji(){
      this.scene.imageryLayers.addImageryProvider(this.JILINprovider);
      this.scene.imageryLayers.addImageryProvider(this.wurenjiprovider);
    },
    // 控制天气工具的显示与隐藏
    toggleWeather(value) {
      this.weatherTool = value;
    },
    //切换天气特效：根据选择的天气应用特效
    weatherChange(val) {
      this.clearWeatherEffects(); // 每次切换时先清除现有天气效果
      if (val && !weatherObjs[val]) {
        switch (val) {
          case "rain":
            weatherObjs.rain = new WeatherRain(this.viewer, { tiltAngle: -0.2, rainSize: 0.5, rainSpeed: 380.0 });
            break;
          case "snow":
            weatherObjs.snow = new WeatherSnow(this.viewer, { snowSize: 0.02, snowSpeed: 80.0 });
            break;
          case "fog":
            weatherObjs.fog = new WeatherFog(this.viewer, { visibility: 0.25, color: new Cesium.Color(0.8, 0.8, 0.8, 0.3) });
            break;
        }
      }
      if (weatherObjs[val]) weatherObjs[val].show(true); // 显示选择的天气特效
    },
    // 清除当前的所有天气效果
    clearWeatherEffects() {
      Object.values(weatherObjs).forEach(obj => obj && obj.show(false));
    },
    // 关闭天气特效
    weatherClose() {
      this.clearWeatherEffects();
    },
    // 切换实体的显示与隐藏
    toggleEntitys(value) {
      this.modelEntity.forEach(element => {
        element.show = value
      });      
    },
    // 切换场景的显示与隐藏
    toggleScene(value) {
      this.viewer.scene.globe.show = value;
    },
    // 加载leaflet
    toggleLeaflet(){
      // 禁用leaflet地图的交互
      this.map.dragging.enable();
      this.map.touchZoom.enable();
      this.map.doubleClickZoom.enable();
      this.map.scrollWheelZoom.enable();
      this.map.boxZoom.enable();
      document.getElementById('leafletmap').style.display = 'inline'; // 显示 Leaflet 地图
      document.getElementById('cesiumContainer').style.display = 'none'; // 隐藏 Cesium 地图
      this.viewer.scene.globe.show = false; // 隐藏地球
      this.map.invalidateSize();  // 刷新地图视图
    },
    // 切换Cesium
    toggleCesium(){
      document.getElementById('leafletmap').style.display = 'none';
      document.getElementById('cesiumContainer').style.display = 'inline';
      this.viewer.scene.globe.show = true; 
      // 禁用leaflet地图的交互
      this.map.dragging.disable();
      this.map.touchZoom.disable();
      this.map.doubleClickZoom.disable();
      this.map.scrollWheelZoom.disable();
      this.map.boxZoom.disable();
    },
    // 运动玩具车
    async runToycar(){
      const dataSource = await Cesium.CzmlDataSource.load("3Dmodels/ClampToGround.czml");
      this.viewer.dataSources.add(dataSource);
      this.toycar = dataSource.entities.getById("toycar");
      var positionProperty = this.toycar.position;
      this.toycar.orientation =  new Cesium.VelocityOrientationProperty(positionProperty);
      this.toycar.model.scale = 500; //控制大小
      this.toycar.model.heightReference = Cesium.HeightReference.CLAMP_TO_TERRAIN;  //或者 CLAMP_TO_GROUND;
      this.clock.shouldAnimate = true;
      this.viewer.trackedEntity = this.toycar 
    },
    // 暂停玩具车
    pauseToycar(){
      this.clock.shouldAnimate = false; // 暂停运动
      this.viewer.trackedEntity = undefined
    },
    // 取消暂停玩具车
    resumeToycar(){
      this.clock.shouldAnimate = true; // 继续运动
      this.viewer.trackedEntity = this.toycar 
    },
  },
}
</script>

<style lang="less" scoped>
.layer-btn {
  position: absolute;
  z-index: 6;
}
.cesiumbtn{
  margin-left: 80px;
  margin-top: 20px;
}
.leafletbtn{
  margin-left: 160px;
  margin-top: 20px;
}
.runToycar{
  margin-left: 275px;
  margin-top: 20px;
}
.pauseToycar{
  margin-left: 370px;
  margin-top: 20px;
}
.resumeToycar{
  margin-left: 460px;
  margin-top: 20px;
}
.parents{
  width: 100%;
  height: 100%;
  position: relative;
}
#cesiumContainer{
  position: absolute;
  z-index: 1;
}
#leafletmap{
  position: absolute;
  background-color: white;
  display: none;
  width: 84.9vw;
  height: 92vh;
  z-index: 2;
}
.hand-cursor {
    cursor: pointer; /* 手形光标 */
}
.weather-tools {
    transition: opacity 0.3s ease;
    position: absolute;
    z-index: 10;
    margin-left: 70vw;
    margin-top: 20px;
    :deep(.el-input) {
      .el-input__inner {
        height: 30px;
        width: 120px;
      }
      .el-input__suffix {
        top: 5px;
      }
    }
    .close-btn {
      margin-left: 15px;
      cursor: pointer;
    }
  }
</style>
