<template>
  <div id="app">
    <container></container>
  </div>
</template>

<script>
import container from './components/containerWeb.vue';
export default {
  name: 'App',
  components: {
    container
  }
}
</script>

<style>

</style>
